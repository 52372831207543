import { useState, useEffect } from "react";
import { useLocation, Routes, Route, useNavigate } from "react-router-dom";
import LoginComponent from "../components/app/login";
import DashboardComponent from "../components/app/dashboard";
import HeadersTop from "../components/headers/headersTop";
import Layout from "../components/sideMenu/navSidebar";
import UserList from "../components/app/users";
import Settings from "../components/app/settings";
import HelpSupport from "../components/app/helpSupport";
import UserDetails from "../components/app/userDetails";
import Posts from "../components/app/posts";
import { Get } from "../helper/localStorage";
import PostDetails from "../components/app/postDetails";
import UnderReviewPosts from "../components/app/underReviewPosts";
import Sidebar from "../components/layouts/sidebar"
import Interests from "../components/app/interests";
import AdsManagement from "../components/app/adsManagement";

// global.Buffer = global.Buffer || require('buffer').Buffer;


const AppRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [Session, setSession] = useState(Get('token'));
    const [sidebar, setsidebar] = useState(false)

    const sidebarOpen = () => {
        setsidebar(!sidebar);
    }
    return (
        <>
            {Session ? (<>
                {/* <Layout sidebar={sidebar} /> */}
                {/* <HeadersTop sidebarOpen={sidebarOpen} /> */}
            </>) : ""}
            <Routes >
                <Route exact path="/" element={<Sidebar showSidebar={true} ><DashboardComponent /></Sidebar>} />
                <Route exact path="/login" element={<Sidebar showSidebar={false} ><LoginComponent /></Sidebar>} />
                <Route exact path="/dashboard" element={<Sidebar showSidebar={true} ><DashboardComponent /></Sidebar>} />
                <Route exact path="/users" element={<Sidebar showSidebar={true} ><UserList /></Sidebar>} />
                <Route exact path="/userDetails/:id" element={<Sidebar showSidebar={true} ><UserDetails /></Sidebar>} />
                <Route exact path="/posts" element={<Sidebar showSidebar={true} ><Posts /></Sidebar>} />
                <Route exact path="/postDetails/:id" element={<Sidebar showSidebar={true} ><PostDetails /></Sidebar>} />
                <Route exact path="/settings" element={<Sidebar showSidebar={true} ><Settings /></Sidebar>} />
                <Route exact path="/helpSupport" element={<Sidebar showSidebar={true} ><HelpSupport /></Sidebar>} />
                <Route exact path="/underReviewPosts" element={<Sidebar showSidebar={true} ><UnderReviewPosts /></Sidebar>} />
                <Route exact path="/interests" element={<Sidebar showSidebar={true} ><Interests /></Sidebar>} />
                <Route exact path="/adsManagement" element={<Sidebar showSidebar={true} ><AdsManagement /></Sidebar>} />
            </Routes>
        </>
    );
};

export default AppRoutes;