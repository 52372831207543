/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { getUserDetails } from '../../services/api';
import { toast } from 'react-toastify';
import moment from "moment-timezone";


var Images = []
const UserDetails = () => {
    let navigate = useNavigate();
    const [user, setDetails] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        userDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const projectId = window.location.pathname.split(':');
    if (!projectId[1]) return navigate('/userList', { replace: true });

    async function userDetails() {
        setSpinner(true);
        const details = await getUserDetails({ "userId": projectId[1] });
        setSpinner(false);
        details.code == 200 ? setDetails(details.result) : toast('User not found!')
    }



    return (
        <>
            <div className="main-wrapper">
                <div className="dashboard content-wrapper">
                    <section className="content pb-5 pt-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mb-4">

                                    <div className="d-flex justify-content-between">
                                        <div className='d-flex align-items-center'>
                                            <a className="submit me-3 point" onClick={() => navigate('/users')} ><i className='fa fa-long-arrow-left'></i></a> <h4>User Details</h4>
                                        </div>

                                    </div>
                                </div>
                                <div className="user-details">
                                    <div className='row'>
                                        <div className="col-md-3"></div>
                                        <div className='col-md-6'>
                                            <div className="row align-items-center justify-content-center mb-3 mt-3 py-2">
                                                <div className="col-sm-4">
                                                    <p className='mb-0'>Full Name :</p> </div>
                                                <div className="col-sm-4">
                                                    <h6>{user.fullName ? user.fullName : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center mb-3 py-2">
                                                <div className="col-sm-4">
                                                    <p className='mb-0'>Email-ID :</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6>{user.email ? user.email : 'N/A'}</h6>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center mb-3 py-2">
                                                <div className="col-sm-4">
                                                    <p className='mb-0'>Gender :</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6>{user?.gender ? user.gender : 'N/A'}</h6>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center mb-3 py-2">
                                                <div className="col-sm-4">
                                                    <p className='mb-0'>DOB :</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6>{user.dateOfBirth ? user.dateOfBirth.split("T")[0] : 'N/A'}</h6>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-center mb-3 py-2">
                                                <div className="col-sm-4">
                                                    <p className='mb-0'>Country :</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6>{user?.address ? user.address?.country : 'N/A'}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default UserDetails;