// Layout.js
import React from "react";
import Layout from "../sideMenu/navSidebar";
import HeaderTop from "../headers/headersTop";
const Sidebar = ({ children, showSidebar }) => (
  <div className="app-layout">
    {showSidebar && <Layout />}
    {showSidebar && <HeaderTop />}
    <main>{children}</main>
  </div>
);

export default Sidebar;
