import moment from "moment-timezone";
// const AWS = require('aws-sdk');

export const manageChartData = (data) => {
    var result = [];
    var currentDate = moment();
    switch (data.type) {
        case "WEEK":
            let startWeekDate = moment().weekday(0);
            for (let i = 0; i < moment(currentDate).diff(startWeekDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    users: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("ddd");
                forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "MONTH":
            let startMonthDate = moment().startOf('month').format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(startMonthDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    users: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("D MMM");
                forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "DAILY":
            let lastThirtyDays = moment().subtract(30, "days").format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(lastThirtyDays, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    users: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => {
                    if(element.day == moment(date).date() && element.month == moment(date).month()+1){
                        return element
                    }
                }
                );
                forPush.name = moment(date).format("D MMM");
                forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "YEAR":
            for (let i = 0; i < 12; i++) {
                let forPush = {
                    name: "",
                    users: ""
                }
                let _month = moment().subtract(i,"month").format("MM");
                let dateMatchedObject = data.list.find(element => element.month == _month);
                forPush.name = moment().subtract(i,"month").format("MMM");
                forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            // for (let i = moment().format("MM"); i >= 1; i--) {
            //     let forPush = {
            //         name: "",
            //         users: ""
            //     }
            //     let dateMatchedObject = data.list.find(element => element.month == i);
            //     forPush.name = moment().month(i - 1).format("MMM");
            //     forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
            //     result.unshift(forPush);
            // }
            break;
    }
    return result
}

export const managePostChartData = (data) => {
    var result = [];
    var currentDate = moment();
    switch (data.type) {
        case "WEEK":
            let startWeekDate = moment().weekday(0);
            for (let i = 0; i < moment(currentDate).diff(startWeekDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    posts: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("ddd");
                forPush.posts = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "DAILY":
            let lastThirtyDays = moment().subtract(30, "days").format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(lastThirtyDays, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    posts: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => (element.day == moment(date).date() && element.month == (moment(date).month() + 1)));
                forPush.name = moment(date).format("D MMM");
                forPush.posts = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "MONTH":
            let startMonthDate = moment().startOf('month').format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(startMonthDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    posts: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("D MMM");
                forPush.posts = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "YEAR":
            console.log(moment().format("MM"))
            for (let i = moment().format("MM"); i >= 1; i--) {
                let forPush = {
                    name: "",
                    posts: ""
                }
                let dateMatchedObject = data.list.find(element => element.month == i);
                forPush.name = moment().month(i - 1).format("MMM");
                forPush.posts = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
    }
    return result
}

export const manageViewChartData = (data) => {
    var result = [];
    var currentDate = moment();
    switch (data.type) {
        case "WEEK":
            let startWeekDate = moment().weekday(0);
            for (let i = 0; i < moment(currentDate).diff(startWeekDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    views: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("ddd");
                forPush.views = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "DAILY":
            let lastThirtyDays = moment().subtract(30, "days").format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(lastThirtyDays, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    views: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => (element.day == moment(date).date() && element.month == (moment(date).month() + 1)));
                forPush.name = moment(date).format("D MMM");
                forPush.views = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "MONTH":
            let startMonthDate = moment().startOf('month').format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(startMonthDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    views: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("D MMM");
                forPush.views = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "YEAR":
            console.log(moment().format("MM"))
            for (let i = moment().format("MM"); i >= 1; i--) {
                let forPush = {
                    name: "",
                    views: ""
                }
                let dateMatchedObject = data.list.find(element => element.month == i);
                forPush.name = moment().month(i - 1).format("MMM");
                forPush.views = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
    }
    return result
}

export const manageCommentChartData = (data) => {
    var result = [];
    var currentDate = moment();
    switch (data.type) {
        case "WEEK":
            let startWeekDate = moment().weekday(0);
            for (let i = 0; i < moment(currentDate).diff(startWeekDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    comments: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("ddd");
                forPush.comments = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "DAILY":
            let lastThirtyDays = moment().subtract(30, "days").format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(lastThirtyDays, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    comments: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => (element.day == moment(date).date() && element.month == (moment(date).month() + 1)));
                forPush.name = moment(date).format("D MMM");
                forPush.comments = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "MONTH":
            let startMonthDate = moment().startOf('month').format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(startMonthDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    comments: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("D MMM");
                forPush.comments = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "YEAR":
            console.log(moment().format("MM"))
            for (let i = moment().format("MM"); i >= 1; i--) {
                let forPush = {
                    name: "",
                    comments: ""
                }
                let dateMatchedObject = data.list.find(element => element.month == i);
                forPush.name = moment().month(i - 1).format("MMM");
                forPush.comments = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
    }
    return result
}

export const manageVoteChartData = (data) => {
    var result = [];
    var currentDate = moment();
    switch (data.type) {
        case "WEEK":
            let startWeekDate = moment().weekday(0);
            for (let i = 0; i < moment(currentDate).diff(startWeekDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    votes: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("ddd");
                forPush.votes = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "DAILY":
            let lastThirtyDays = moment().subtract(30, "days").format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(lastThirtyDays, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    votes: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => (element.day == moment(date).date() && element.month == (moment(date).month() + 1)));
                forPush.name = moment(date).format("D MMM");
                forPush.votes = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "MONTH":
            let startMonthDate = moment().startOf('month').format('YYYY-MM-DD');
            for (let i = 0; i < moment(currentDate).diff(startMonthDate, "days") + 1; i++) {
                let forPush = {
                    name: "",
                    votes: ""
                }
                let date = moment().subtract(i, 'days').toISOString();
                let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
                forPush.name = moment(date).format("D MMM");
                forPush.votes = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
        case "YEAR":
            console.log(moment().format("MM"))
            for (let i = moment().format("MM"); i >= 1; i--) {
                let forPush = {
                    name: "",
                    votes: ""
                }
                let dateMatchedObject = data.list.find(element => element.month == i);
                forPush.name = moment().month(i - 1).format("MMM");
                forPush.votes = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
                result.unshift(forPush);
            }
            break;
    }
    return result
}


// const convertWeek = () =>{
//     let forPush = {
//         name: "",
//         users: ""
//     }
//     console.log('inside week');
//     for (let i = 0; i < 7; i++) {
//         let date = moment().subtract(i, 'days').toISOString();
//         let dateMatchedObject = data.list.find(element => element.day == moment(date).date());
//         forPush.name = moment(date).format("ddd");
//         forPush.users = dateMatchedObject?.count ? dateMatchedObject?.count : 0;
//         result.unshift(forPush);
//     }
// }