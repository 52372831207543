("use Strict");
import React, { useEffect, useState } from "react";
import { getGroups, getLabels, getLabelsWithGroup } from "../../services/api";
import { Set, Get } from "../../helper/localStorage";
import LoadingBar from "react-top-loading-bar";
import Card from "react-bootstrap/Card";
import Pagination from "@material-ui/lab/Pagination";
import Button from "react-bootstrap/Button";
import AddGroupModal from "./addGroupModal";
import { MdOutlineEdit } from "react-icons/md";

const Interests = () => {
  const [progress, setProgress] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    Get("pageNumber") ? Get("pageNumber") : 1
  );
  const [labels, setLabels] = useState([]);
  const [count, setCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [title, setTitle] = useState("");
  const [selectLabels, setSelectLabels] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [labelsInGroup, setLabelsInGroup] = useState([]);
  const [groupId, setGroupId] = useState("");

  useEffect(() => {
    groupListing();
  }, [pageNumber]);

  useEffect(() => {
    labelListing();
  }, []);

  const labelListing = async () => {
    const labels = await getLabels();
    if (labels.code === 200) {
      setLabels([...labels.interests]);
      const selectlabels = labels.interests.map((interest) => {
        return { value: interest._id, label: `${interest.name}` };
      });
      setSelectLabels([...selectlabels]);
    }
  };

  const handleChange = (event, value) => {
    setPageNumber(value);
    Set(Storage.PAGE_NUMBER, value);
  };

  const groupListing = async () => {
    setProgress(20);
    const groupsList = await getGroups({
      pageNumber: pageNumber,
    });
    if (groupsList.code === 200) {
      setGroups([...groupsList.groups]);
    }
    setProgress(100);
    setCount(Math.ceil(groupsList.count / 20));
  };

  const labelsInGroupListing = async (groupId) => {
    setGroupId(groupId);
    const labelsInGroup = await getLabelsWithGroup({ groupId: groupId });
    if (labelsInGroup.code === 200) {
      const labels = labelsInGroup.interests.map((interest) => {
        return { value: interest._id, label: `${interest.name}` };
      });
      setLabelsInGroup([...labels]);
    }
  };

  const handleShowModal = (key) => {
    setShowModal(true);
    setTitle(key);
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph px-2">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="pl-3 mb-4">Interests</h4>
                      <div className="search-bar mb-4">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <Button
                            variant="outline-primary"
                            className="pl-6"
                            onClick={() => handleShowModal("Groups")}
                          >
                            Add New Group
                          </Button>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {groups.map((group, ind) => {
                    return (
                      <div className="col-md-4 mb-3" key={ind}>
                        <Card>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            {group.name} - {group.count}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="interest mx-1">
                                <MdOutlineEdit
                                  onClick={() => {
                                    handleShowModal("Edit");
                                    setGroupName(group.name);
                                    labelsInGroupListing(group._id);
                                  }}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {groups.length ? (
                    <Pagination
                      style={{ display: "block", float: "right" }}
                      onChange={handleChange}
                      page={pageNumber}
                      count={count}
                    />
                  ) : (
                    <p
                      style={{
                        marginBottom: 100,
                        marginTop: 25,
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      No data found!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
          <AddGroupModal
            show={showModal}
            setShowModal={setShowModal}
            title={title}
            labels={labels}
            selectLabels={selectLabels}
            groupName={groupName}
            labelsInGroup={labelsInGroup}
            setLabelsInGroup={setLabelsInGroup}
            groupId={groupId}
            groupListing={groupListing}
            labelListing={labelListing}
          />
        </div>
      </div>
    </>
  );
};

export default Interests;
