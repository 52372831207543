import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { postGroup, updateLabel } from "../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";

const AddGroupModal = (props) => {
  console.log(props.groupName,"propsjhgfghjk");
  const [input, setInput] = useState("");
  const [interestIds, setInterestIds] = useState([]);
  const [removedInterestIds, setRemovedInterestIds] = useState([]);
  const [editInput, setEditInput] = useState(props.groupName);
  console.log(props.groupName);

  useEffect(() => {
    setEditInput(props.groupName);
  }, [props.groupName]);

  const handleAddGroup = async () => {
    const group = await postGroup({
      groupName: input,
      interestIds: interestIds,
    });
    if (group.code === 200) {
      props.groupListing();
      props.labelListing();
      handleModalClose();
      toast.success(group.message, { autoClose: 3000, toastId: "added" });
    } else {
      toast.error(group.message, { autoClose: 3000, toastId: "error" });
    }
    setInterestIds([]);
  };

  const handleLabelUpdate = async () => {
    const data = {
      groupId: props.groupId,
      interestIds: interestIds,
      removedInterestIds: removedInterestIds,
    };
    if (editInput) data["groupName"] = editInput;
    const update = await updateLabel(data);
    if (update.code === 200) {
      props.groupListing();
      props.labelListing();
      handleModalClose();
      toast.success(update.message, { autoClose: 3000, toastId: "label" });
    } else {
      toast.error(update.message, { autoClose: 3000, toastId: "labelErr" });
    }
    setRemovedInterestIds([]);
    setInterestIds([]);
    setEditInput("");
  };

  const handleModalClose = () => {
    props.setShowModal(false);
  };

  const handleChange = (data, type) => {
    if (type.action === "remove-value") {
      props.setLabelsInGroup(
        props.labelsInGroup.filter(
          (label) => label.value !== type.removedValue.value
        )
      );
      setRemovedInterestIds([...removedInterestIds, type.removedValue]);
    } else {
      setRemovedInterestIds([...type.removedValues]);
      props.setLabelsInGroup([]);
    }
  };

  return (
    <Modal
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={handleModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title === "Groups"
            ? "Add New Group"
            : props.title === "Edit"
            ? // <input
              //   title="Click here to edit group name"
              //   type="text"
              //   // value={props.groupName}
              //   onChange={(e) => setEditInput(e.target.value)}
              //   className="form-control rounded-pill"
              //   placeholder={props.groupName}
              // />
              "Edit Group"
            : props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.title === "Groups" ? (
          <>
            <input
              type="text"
              onChange={(e) => setInput(e.target.value)}
              className="form-control mb-3 rounded-pill"
              placeholder="Group Name"
            />
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Assign Interests"
              name="system"
              isMulti
              options={props.selectLabels}
              onChange={(e) => setInterestIds([...e])}
            />
          </>
        ) : (
          <>
            <input
              // title="Click here to edit group name"
              type="text"
              value={editInput}
              onChange={(e) => setEditInput(e.target.value)}
              className="form-control rounded-pill mb-4"
              placeholder="Group Name"
            />
            <Select
              value={props.labelsInGroup}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Assigned Interests"
              name="system"
              isMulti
              onChange={(e, type) => handleChange(e, type)}
            />
            <br />
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Available Interests to Assign"
              name="Available Interests to add"
              isMulti
              options={props.selectLabels}
              onChange={(e) => setInterestIds([...e])}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={handleModalClose}>
          Close
        </Button>
        {props.title === "Groups" ? (
          <Button variant="outline-primary" onClick={() => handleAddGroup()}>
            Add Group
          </Button>
        ) : (
          <Button variant="outline-primary" onClick={() => handleLabelUpdate()}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddGroupModal;
