import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { countryChart } from "../../services/api";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      //   position: "top",
    },
    title: {
      display: false,
      text: "Users From Different Countries",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
};

const CountryChart = () => {
  const [record, setRecord] = useState([0, 0, 0]);
  const [label, setLabel] = useState([0, 0, 0]);
  const [latestSpin, setLatestSpin] = useState(false);

  useEffect(() => {
    occupantsDetail();
  }, []);

//   const getRandomColor = () => {
//     const letters = "0123456789ABCDEF";
//     let color = "#";
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

  const occupantsDetail = async () => {
    setLatestSpin(true);
    let res = await countryChart();
    if (res.code == 200) {
      const labels = res.result.map((item) => item._id);
      const values = res.result.map((item) => item.count);
      setLabel(labels);
      setRecord(values);
      setLatestSpin(false);
    } else {
      // toast.error(res.message);
      // setLatestSpin(false);
    }
  };
  const data = {
    labels: label,
    datasets: [
      {
        data: record,
        backgroundColor: "#204FA7",
      },
    ],
  };
  // console.log(data);

  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <h5 className="mb-0 text-start">Users From Different Countries</h5>
          <div className="d-flex align-items-center">
            <div
              className="refresh-icon me-2"
              onClick={() => occupantsDetail()}
            >
              <i
                className={
                  "fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")
                }
              ></i>
            </div>
          </div>
        </div>
        <div className="countryChart graph occupant-detail-graph">
          <Bar options={options} data={data} />
        </div>
      </div>
    </>
  );
};

export default CountryChart;
