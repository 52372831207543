/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { postStatistics, postAverage } from "../../services/api";
import ProgressBar from "react-bootstrap/ProgressBar";
import { toast } from "react-toastify";
import moment from "moment-timezone";

var Images = [];
const PostDetails = () => {
  let navigate = useNavigate();
  const [totalView, setTotalView] = useState(0);
  const [totalComment, setTotalComment] = useState(0);
  const [totalVote, setTotalVote] = useState(0);
  const [yes, setYes] = useState(0);
  const [no, setNo] = useState(0);
  const [postDetails, setPostDetails] = useState({
    firstLabel: "Yes",
    secondLabel: "No",
  });
  const [countries, setCountries] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [ageFilter, setAgeFilter] = useState("All");
  const [male, setMale] = useState(0);
  const [female, setFemale] = useState(0);
  const [totalCountriesCount, setTotalCountriesCount] = useState(0);
  const [totalAgeFilterCount, setTotalAgeFilterCount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const dataFetchedRef = useRef(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedValue, setSelectedValue] = useState("today");
  const now = 60;

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getPostStatistics();
    getPostAverage();
  }, []);
  useEffect(() => {
    getPostStatistics();
    getPostAverage();
  }, [selectedValue]);

  useEffect(() => {
    // console.log(genderData);
    if (ageFilter == "All") {
      // console.log("goes in all section");
      if (genderData.All) {
        const sum = genderData?.All?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
        setTotalAgeFilterCount(sum);
        setAgeData(genderData.All || []);
      }
    } else if (ageFilter == "Men") {
      // console.log("goes in Men section");
      if (genderData.Men) {
        const sum = genderData?.Men?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
        setTotalAgeFilterCount(sum);
        setAgeData(genderData.Men || []);
      }
    } else {
      // console.log("goes in female section");
      if (genderData.Female) {
        const sum = genderData?.Female?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
        setTotalAgeFilterCount(sum);
        setAgeData(genderData.Female || []);
      }
    }
  }, [ageFilter]);

  const projectId = window.location.pathname.split(":");
  if (!projectId[1]) return navigate("/posts", { replace: true });

  const getPostStatistics = async () => {
    setSpinner(true);
    const post = await postStatistics({
      startDate: startDate,
      endDate: endDate,
      postId: projectId[1],
    });
    if (post.code == 200) {
      setPostDetails(post.postDetails);
      setTotalView(post.totalViews);
      setMale(post.Men);
      setFemale(post.Women);
      setYes(post.Yes);
      setNo(post.No);
      setTotalCountriesCount(post.totalCountriesCount);
      setCountries(post.countries);
      setCountries(post.countries);
      setGenderData(post.genderData);
      setTotalAgeFilterCount(
        post.genderData.All?.above45 +
          post.genderData.All?.below13 +
          post.genderData.All?.from13to17 +
          post.genderData.All?.from18to24 +
          post.genderData.All?.from24to35 +
          post.genderData.All?.from35to45
      );
      setAgeData(post.genderData.All);
      setSpinner(false);
    } else {
      toast.error(post.message);
      setSpinner(false);
    }
  };

  const getPostAverage = async () => {
    setSpinner(true);
    const post = await postAverage({
      startDate: startDate,
      endDate: endDate,
      postId: projectId[1],
    });
    if (post.code == 200) {
      setTotalComment(post.result.comment);
      setTotalVote(post.result.react);
      setSpinner(false);
    } else {
      toast.error(post.message);
      setSpinner(false);
    }
  };
  function handleSelectChange(event) {
    if (event.target.value == "today") {
      setStartDate(moment().subtract(1, "day"));
    } else if (event.target.value == "lastWeek") {
      setStartDate(moment().subtract(6, "days"));
    } else {
      setStartDate(moment().subtract(1, "month"));
    }
    setSelectedValue(event.target.value);
  }

  function average() {
    return (totalComment + totalVote / totalView) * 100
      ? (((totalComment + totalVote) / totalView) * 100).toFixed(2)
      : 0;
  }

  return (
    <>
      <div className="main-wrapper">
        <div className="dashboard content-wrapper">
          <section className="content pb-5 pt-3">
            <div className="container-fluid px-4">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="pl-3">Views</h4>
                    <select value={selectedValue} onChange={handleSelectChange}>
                      <option value="today">Today</option>
                      <option value="lastWeek">Last week</option>
                      <option value="lastMonth">Last month</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="card-body d-flex justify-content-center align-items-center inset-outer-shadow bg-white">
                    <div className="card-content text-center">
                      <h5>{totalView}</h5>
                      <p className="mb-2">Total Views</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="card-body d-flex justify-content-center align-items-center inset-outer-shadow bg-white">
                    <div className="card-content text-center">
                      <h5>{yes}</h5>
                      <p className="mb-2">{postDetails.firstLabel}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="card-body d-flex justify-content-center align-items-center inset-outer-shadow bg-white">
                    <div className="card-content text-center">
                      <h5>{no}</h5>
                      <p className="mb-2">{postDetails.secondLabel}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="card-body d-flex justify-content-center align-items-center inset-outer-shadow bg-white">
                    <div className="card-content text-center">
                      <h5>{average()} %</h5>
                      <p className="mb-2">Average Engagement</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <h4 className="pl-3">Countries</h4>
                </div>
                {countries.map((item, index) => {
                  return (
                    <div className="col-12 mb-3">
                      <h6 className="mb-2">{item._id}</h6>
                      <ProgressBar
                        now={
                          ((item.count * 100) / totalCountriesCount).toFixed(
                            2
                          ) || 0
                        }
                        label={`${
                          ((item.count * 100) / totalCountriesCount).toFixed(
                            2
                          ) || 0
                        }%`}
                      />
                    </div>
                  );
                })}

                <div className="col-12 mt-5 mb-3">
                  <h4 className="pl-3">Age</h4>
                </div>
                <ul
                  className="nav nav-tabs border-0 ps-2 mb-5"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#all-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="all-tab-pane"
                      aria-selected="true"
                      onClick={() => setAgeFilter("All")}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="men-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#men-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="men-tab-pane"
                      aria-selected="false"
                      onClick={() => setAgeFilter("Men")}
                    >
                      Men
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="women-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#women-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="women-tab-pane"
                      aria-selected="false"
                      onClick={() => setAgeFilter("Female")}
                    >
                      Women
                    </button>
                  </li>
                </ul>
                <div className="row">
                  {ageData.map((item, index) => {
                    return (
                      <>
                        <div className="col-12 mb-3">
                          <h6 className="mb-2">{item.text}</h6>
                          <ProgressBar
                            now={
                              Math.round(
                                (item.value * 100) / totalAgeFilterCount
                              ) || 0
                            }
                            label={`${
                              Math.round(
                                (item.value * 100) / totalAgeFilterCount
                              ) || 0
                            }%`}
                          />
                        </div>
                      </>
                    );
                  })}
                  {/* <div className="col-12 mb-3">
                    <h6 className="mb-2">Below 13</h6>
                    <ProgressBar now={Math.round(ageData?.below13 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.below13 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6 className="mb-2">13-17</h6>
                    <ProgressBar now={Math.round(ageData?.from13to17 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.from13to17 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6 className="mb-2">18-24</h6>
                    <ProgressBar now={Math.round(ageData?.from18to24 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.from18to24 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6 className="mb-2">24-35</h6>
                    <ProgressBar now={Math.round(ageData?.from24to35 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.from24to35 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6 className="mb-2">35-45</h6>
                    <ProgressBar now={Math.round(ageData?.from35to45 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.from35to45 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div>
                  <div className="col-12 mb-3">
                    <h6 className="mb-2">45+</h6>
                    <ProgressBar now={Math.round(ageData?.above45 * 100 / totalAgeFilterCount) || 0} label={`${Math.round(ageData?.above45 * 100 / totalAgeFilterCount) || 0}%`} />
                  </div> */}
                </div>
                <div className="col-12 mt-5 mb-3">
                  <h4 className="pl-3">Gender</h4>
                </div>
                <div className="col-12">
                  <div className="gender d-flex">
                    <div className="men-item me-3 d-flex justify-content-between align-items-end">
                      <div className="men-content">
                        <img
                          src="images/male-gender-1.png"
                          alt=""
                          className="img-fluid mb-2"
                        />
                        <h6 className="text-white">Men</h6>
                      </div>
                      <div className="men-count">
                        <h6 className="text-white">{male}</h6>
                      </div>
                    </div>
                    <div className="men-item women-item d-flex justify-content-between align-items-end">
                      <div className="men-content">
                        <img
                          src="images/male-gender-2.png"
                          alt=""
                          className="img-fluid mb-2"
                        />
                        <h6 className="text-white">Women</h6>
                      </div>
                      <div className="men-count">
                        <h6 className="text-white">{female}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
