import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logOut } from "../../services/api";
import postImg from "../../images/post.svg";
import reviewImg from "../../images/review.svg";
import adImg from "../../images/marketing.png";
import interestImg from "../../images/bookmark.png";

export default function Layout(props) {
  const dataFetchedRef = useRef(false);
  let navigate = useNavigate();
  let navLink = window.location.pathname;
  const [currentRoute, setCurrentRoute] = useState("/");
  const [activeClass, setActiveClass] = useState("active");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {}, []);

  useEffect(() => {
    // console.log(navLink, "sideMenu navlink value--------");
    detailsRoutes();
  }, [navLink]);

  function changeRoutes() {
    // Set(Storage.PAGE_NUMBER, 1);
    // Set(Storage.SEARCH, "");
    detailsRoutes();
  }

  const detailsRoutes = () => {
    let splitLink = navLink.split("/");
    // console.log(splitLink);
    switch (splitLink[1]) {
      case "userDetails":
        setCurrentRoute("/users");
        setActiveClass("active");
        break;
      case "influencerDetails":
        setCurrentRoute("/influencer");
        setActiveClass("active");
        break;
      case "postDetails":
        setCurrentRoute("/posts");
        setActiveClass("active");
        break;
      default:
        localStorage.setItem("pageNumber", 1);
        setActiveClass("");
        break;
    }
  };

  const logOut = () => {
    localStorage.clear();
    navigate(`/login`, { replace: true });
    // logOut()
  };
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  });
  return (
    <>
      <nav
        id="sidebar"
        className={`sidebarr ${props.sidebar ? "open-sidebar" : ""}`}
      >
        <div className="p-4">
          <ul className="list-unstyled components mb-5 point">
            <li>
              <NavLink
                className={"myClass d-flex " + (navLink == "/" ? "active" : "")}
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/dashboard"
              >
                <img
                  src="images/dashboard.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex " +
                  (currentRoute == "/users" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/users"
              >
                <img src="images/user.svg" alt="" className="img-fluid me-3" />
                Users Management
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex " +
                  (currentRoute == "/posts" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/posts"
              >
                <img src={postImg} alt="" className="img-fluid me-3" />
                Posts Management
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex " +
                  (currentRoute == "/underReviewPosts" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/underReviewPosts"
              >
                <img src={reviewImg} alt="" className="img-fluid me-3" />
                Under Review Posts
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex " +
                  (currentRoute == "/interests" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/interests"
              >
                <img src={interestImg} alt="" className="img-fluid me-3" />
                Interests
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex " +
                  (currentRoute == "/adsManagement" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/adsManagement"
              >
                <img src={adImg} alt="" className="img-fluid me-3" />
                Ads Management
              </NavLink>
            </li>
            <li>
              <NavLink
                className={
                  "myClass d-flex" +
                  (currentRoute == "/settings" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/settings"
              >
                <img
                  src="images/settings.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Setting
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={
                  "myClass d-flex" + (state == "/helpSupport" ? activeClass : "")
                }
                onClick={() => {
                  changeRoutes();
                  windowWidth <= 1200 && props.stateSideBar();
                }}
                to="/helpSupport"
              >
                <img src="images/help.svg" alt="" className="img-fluid me-3" />
                Help Support
              </NavLink>
            </li> */}
            <li>
              <a className="myClass" onClick={() => logOut()}>
                <img
                  src="images/logout.svg"
                  alt=""
                  className="img-fluid me-3"
                />
                Log Out
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
