/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Storage } from "../../helper/appResource";
import { toast } from "react-toastify";
import { updatePassword } from "../../services/api";
import LoadingBar from "react-top-loading-bar";

const ChangePassword = () => {
  let initialState = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [state, setState] = useState(initialState);
  const [spinner, setSpinner] = useState(0);

  const onChangeHandle = (event) => {
    setState((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const changePassword = () => {
    if (!state.password || !state.password.trim())
      return toast.error(Storage.OLD_PASSWORD_REQUIRED);
    if (!state.newPassword || !state.newPassword.trim())
      return toast.error(Storage.NEW_PASSWORD_REQUIRED);
    if (!state.confirmPassword || !state.confirmPassword.trim())
      return toast.error(Storage.CONFIRM_PASSWORD_REQUIRED);
    if (
      state.password.length < 6 ||
      state.newPassword.length < 6 ||
      state.confirmPassword.length < 6
    )
      return toast.error(Storage.PASSWORD_VALIDATION);
    // console.log(state.newPassword !== state.confirmPassword)

    if (state.newPassword !== state.confirmPassword)
      return toast.error(Storage.PASSWORD_NOT_MATCHED);

    setSpinner(20);
    updatePassword(state)
      .then((data) => {
        setSpinner(100);
        if (data.code === 200) {
          setState(initialState);
          toast.success(Storage.PASSWORD_UPDATED);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={spinner}
        onLoaderFinished={() => setSpinner(0)}
        height={4}
      />
      <div className="p-24 mt-4">
        <form className="d-flex flex-column justify-content-center align-items-center">
          <div className="row gx-3 mb-3 w-100 justify-content-center">
            <div className="col-md-6 text-left">
              <label className="small mb-1">Old Password</label>
              <input
                type="text"
                name="password"
                className="form-control"
                placeholder="Enter your password"
                value={state.password}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <div className="row gx-3 mb-3 w-100 justify-content-center">
            <div className="col-md-6 text-left">
              <label className="small mb-1">New Password</label>
              <input
                name="newPassword"
                className="form-control"
                type="password"
                placeholder="Enter new password"
                value={state.newPassword}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <div className="row gx-3 mb-4 w-100 justify-content-center">
            <div className="col-md-6 text-left">
              <label className="small mb-1">Confirm New Password</label>
              <input
                name="confirmPassword"
                className="form-control"
                type="password"
                placeholder="Enter confirm password"
                value={state.confirmPassword}
                onChange={onChangeHandle}
              />
            </div>
          </div>
          <button
            disabled={
              !state.password || !state.newPassword || !state.confirmPassword
            }
            className="btn btn-primary"
            type="button"
            onClick={() => changePassword()}
          >
            {" "}
            {spinner ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}{" "}
            Save{" "}
          </button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
