("use Strict");
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Storage } from "../../helper/appResource";
import { toast } from "react-toastify";
import Select from "react-select";
import Footer from "../footer/footer"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const options = [
    { value: "ALL", label: "ALL" },
    { value: true, label: "Activated" },
    { value: false, label: "Deactivated" },
];
const genderFilter = [
    { value: "ALL", label: "All" },
    { value: "6318500f2c0002eadb9f8141", label: "Women" },
    { value: "63184dbecef30244576b7cca", label: "Men" },
    { value: "6375e93d53577e82d0e67952", label: "Binary" },
];
const HelpSupport = () => {
    let timerCount = null;
    const navigate = useNavigate();
    const [List, setList] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(localStorage.getItem("pageNumber") ? localStorage.getItem("pageNumber") : 1);
    const [search, setSearch] = useState(localStorage.getItem("searchKey"));
    const [spinner, setSpinner] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userStatus, setUserStatus] = useState(true);
    const [dialog, setDialog] = useState("");
    const [buttonSpin, setButtonSpin] = useState(false);
    const [filterStatus, setFilterStatus] = useState("ALL");
    // const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <div className="dashboard">
                    <section className="content pt-3">
                        <div className="container-fluid">
                            <div className="new-graph">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="dashboard-table mb-3 pb-3">
                                            <div className="table-responsive px-2">
                                                <div className="d-flex flex-column flex-lg-row justify-content-between">
                                                    <h4 className="pl-3">Help Support</h4>
                                                    <div className="search-bar">
                                                        <div className="d-flex flex-column flex-md-row align-items-center">
                                                            <input
                                                                type="text"
                                                                // onChange={(event) =>
                                                                //   searchEvent(event.target.value)
                                                                // }
                                                                // value={search}
                                                                placeholder="Search"
                                                                className="search-box"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <Footer /> */}
                </div>
            </div>
        </>
    );
};

export default HelpSupport;
