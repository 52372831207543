("use Strict");
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers, changeUserStatus } from "../../services/api";
import { Storage } from "../../helper/appResource";
import { Set, Get } from "../../helper/localStorage";
import { toast } from "react-toastify";
import Select from "react-select";
import Footer from "../footer/footer"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Pagination from "@material-ui/lab/Pagination";
import LoadingBar from 'react-top-loading-bar'




const options = [
  { value: "", label: "All Users" },
  { value: true, label: "Activated" },
  { value: false, label: "Blocked" },
];

const premium = [
  { value: "", label: "All Subscriptions" },
  { value: true, label: "Premium" },
  { value: false, label: "Freemium" },
];

const UserList = () => {
  let timerCount = null;
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0)
  const [List, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(Get("pageNumber") ? Get("pageNumber") : 1);
  const [search, setSearch] = useState(localStorage.getItem("searchKey"));
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userStatus, setUserStatus] = useState(true);
  const [dialog, setDialog] = useState("");
  const [buttonSpin, setButtonSpin] = useState(false);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [premiumStatus, setPremiumStatus] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    userListing();
  }, [pageNumber, search, filterStatus, premiumStatus]);

  const userListing = async () => {
    setProgress(20)
    const listing = await getUsers({
      status: filterStatus,
      premium: premiumStatus,
      pageNumber: pageNumber,
      searchKey: search
    });
    setProgress(100)
    setList(listing.result);
    setCount(Math.ceil(listing.count / 10));
  };

  const getUserId = (id) => {
    Set(Storage.PAGE_NUMBER, pageNumber);
    Set(Storage.SEARCH, search);
    navigate(`/userDetails/:${id}`);
  };
  const handleChange = (event, value) => {
    setPageNumber(value);
    Set(Storage.PAGE_NUMBER, value);
    Set(Storage.SEARCH, search);
  };

  const searchEvent = (value) => {
    if (timerCount != null) clearTimeout(timerCount);
    timerCount = setTimeout(() => {
      setSearch(value);
      setPageNumber(1);
    }, 1000);
  };

  const openConfirmDialog = (value, status) => {
    setUserId(value);
    setUserStatus(status === true ? false : true);
    handleShow();
    setDialog(
      status === true ? (
        <p>{Storage.DEACTIVATE_USER}</p>
      ) : (
        <p>{Storage.ACTIVATE_USER}</p>
      )
    );
  };

  const updateStatus = async () => {
    setButtonSpin(true);
    const updateStatus = await changeUserStatus({
      userId: userId,
      status: userStatus,
    });
    if (updateStatus.code === 200) {
      userListing();
      handleClose(false);
      setButtonSpin(false);
      toast.success(Storage.STATUS_SUCCESS);
    } else {
      toast.error(updateStatus.message);
    }
  };


  // useEffect(() => {
  //   setPageNumber(1);
  //   userListing();
  // }, [filterStatus, genderFilterStatus]);

  // useEffect(() => {
  //   setPageNumber(1);
  //   userListing();
  // }, [genderFilterStatus]);

  return (
    <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph">
                <div className="row">
                  <div className="col-md-12">
                    <div className="dashboard-table mb-3 pb-3">
                    <div className="d-flex flex-column flex-lg-row justify-content-between">
                          <h4 className="pl-3">Users</h4>
                          <div className="search-bar">
                            <div className="d-flex flex-column flex-md-row align-items-center">
                              <Select
                                className="basic-single me-md-3 mb-3 mb-md-0"
                                classNamePrefix="select"
                                // isLoading={isLoading}
                                name="color"
                                options={options}
                                defaultValue={options[0]}
                                onChange={(e) => { setFilterStatus(e.value); setPageNumber(1) }}
                              />
                              <Select
                                className="basic-single me-md-3 mb-3 mb-md-0"
                                classNamePrefix="select"
                                // isLoading={isLoading}
                                name="color"
                                options={premium}
                                defaultValue={premium[0]}
                                onChange={(e) => { setPremiumStatus(e.value); setPageNumber(1) }}
                              />
                              <input
                                type="text"
                                onChange={(event) =>
                                  searchEvent(event.target.value)
                                }
                                placeholder="Search"
                                className="search-box"
                              />
                            </div>
                          </div>
                        </div>
                      <div className="table-responsive px-2">
                        <table id="example2" className="table table-hover">
                          <tbody>
                            {List.map((item, index) => {
                              return (
                                <tr className="point" key={index.toString()}>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div className="text-center">
                                      <h3 className="mb-0">
                                        {(pageNumber - 1) * 10 + index + 1}
                                      </h3>
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div>
                                      <h3 className="mb-0">{item.fullName}</h3>
                                      <h5 className="mb-0">Full Name</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div>
                                      <h3 className="mb-0">
                                        {item.email ? item.email : "N/A"}
                                      </h3>
                                      <h5 className="mb-0">Email-ID</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div>
                                      <h3 className="mb-0">
                                        {item?.gender
                                          ? item.gender
                                          : "N/A"}
                                      </h3>
                                      <h5 className="mb-0">Gender</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div>
                                      <h3 className="mb-0">
                                        {item.active
                                          ? "Activated"
                                          : "Blocked"}
                                      </h3>
                                      <h5 className="mb-0">User Status</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <div className="text-center">
                                      <h3 className="mb-0">
                                        {item.isSubscription ? (
                                          <i className="fa fa-check" aria-hidden="true" style={{ color: "#0fbb52", fontSize: 25 }}></i>
                                        ) : (
                                          <i className="fa fa-times" aria-hidden="true" style={{ color: "#ff4848", fontSize: 25 }}></i>
                                        )}
                                      </h3>
                                      <h5 className="mb-0">{item.isSubscription? "Premium" : "Freemium"}</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() =>
                                      openConfirmDialog(item._id, item.active)
                                    }
                                  >
                                    <div className="text-center">
                                      <h3 className="mb-0">
                                        {item.active ? (
                                          <i className="fa fa-check" aria-hidden="true" style={{ color: "#0fbb52", fontSize: 25 }}></i>
                                        ) : (
                                          <i className="fa fa-times" aria-hidden="true" style={{ color: "#ff4848", fontSize: 25 }}></i>
                                        )}
                                      </h3>
                                      <h5 className="mb-0">Change Status</h5>{" "}
                                    </div>
                                  </td>
                                  <td
                                    className="border-0"
                                    onClick={() => getUserId(item._id)}
                                  >
                                    <i
                                      className="fa fa-angle-right"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <Spinner
                          className="spinner-style"
                          radius={60}
                          color={"#1387c2"}
                          visible={spinner}
                        /> */}
                        <div>
                          {List.length ? (
                            <Pagination
                              style={{ display: "block", float: "right" }}
                              onChange={handleChange}
                              page={pageNumber}
                              count={count}
                            />
                          ) : (
                            <p
                              style={{
                                marginBottom: 100,
                                marginTop: 25,
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              No data found!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Footer /> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{dialog}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateStatus}>
            {buttonSpin ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}
            {userStatus ? "Activate" : "Block"}          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserList;
