import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { login } from "../../services/api";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Storage } from "../../helper/appResource";
// import LogoImg from "../../images/logo.png";

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isForgotPassword, setForgotPassword] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    // console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token"))
      navigate("/dashboard", { replace: true });
  }, []);

  async function loginUser() {
    if (!email) return toast.error(Storage.USER_NAME_REQUIRED);
    if (!password && !password.trim())
      return toast.error(Storage.PASSWORD_REQUIRED);
    if (password.length < 6) return toast.error(Storage.PASSWORD_VALIDATION);
    setSpinner(true);
    let response = await login({ email, password });
    setSpinner(false);
    // eslint-disable-next-line eqeqeq
    if (response.code == 200) {
      localStorage.setItem("token", JSON.stringify(response.token));
      navigate("/dashboard", { replace: true });
    } else {
      return toast.error(response.message);
    }
  }
  const loginStyle = {
    maxWidth: "240px",
  };

  return (
    <section className="login w-100">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-11">
            <div className="row justify-content-center align-items-center g-0">
              <div className="col-lg-4">
                <div className="login-container text-center px-4 py-5">
                  <h4 className="mb-4">Versus Media Login</h4>
                  <div className="login-logo mb-3 mx-auto">
                    <img alt="" className="img-fluid" />
                  </div>

                  {isForgotPassword ? (
                    <div className="input-box mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                      ></input>
                    </div>
                  ) : (
                    <div>
                      <div className="input-box mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                        ></input>
                      </div>
                      <div className="input-box mb-3">
                        <input
                          type="password"
                          className="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                        ></input>
                      </div>
                      <div className="sign-in-btn">
                        <button
                          type="button"
                          disabled={spinner}
                          onClick={() => loginUser()}
                          className="btn btn-primary w-100 border-0 d-flex align-items-center justify-content-center"
                        >
                          {spinner ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                          Sign in
                        </button>
                      </div>
                    </div>
                  )}

                  {/* <div className="forget-password mt-5">
                                <a className="forgotPassword" onClick={(e) => setForgotPassword(true)} >Forgot your password?</a>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginComponent;
