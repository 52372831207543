import logo from './logo.svg';
import AppRoutes from "./routes/index";
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AppRoutes />
      </div>
    </BrowserRouter>

  );
}

export default App;
