import React, { PureComponent } from "react";
import { useState, useEffect } from "react";
import { VoteChart } from "../../services/api";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { manageVoteChartData } from "../../services/common";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const VotesChart = () => {
  const [data, setData] = useState({});
  const [filter, setFilter] = useState("DAILY");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  });

  useEffect(() => {
    getChartDetails();
  }, [filter]);

  const getChartDetails = async () => {
    setProgress(20);
    const result = await VoteChart({ filter });
    if (result.code === 200) {
      let _data = await manageVoteChartData({
        type: filter,
        list: result.result,
      });
      // console.log(_data);
      setData(_data);
      setProgress(100);
    }
    setProgress(100);
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div>
          <h5 className="mb-3 mb-md-0">Votes</h5>
          {/* <p className="mb-4">
            <span className="green-color">(+23%)</span> than last week
          </p> */}
        </div>
        <div className="buttons">
          <button
            onClick={() => setFilter("DAILY")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filter == "DAILY" ? " selectedButton" : "")
            }
          >
            DAILY
          </button>
          <button
            onClick={() => setFilter("WEEK")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filter == "WEEK" ? " selectedButton" : "")
            }
          >
            CURRENT WEEK
          </button>
          {/* <button onClick={() => setFilterType("YEAR")} className={"btn mx-md-2 mb-2 mb-md-0" + (filterType == "YEAR" ? " selectedButton" : "")}>Year</button> */}
        </div>
      </div>
      <ResponsiveContainer width={"100%"} aspect={windowWidth <= 576 ? 1 : 2.6}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 30, right: 24, left: -9, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0fbb52" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#ff4848" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0fbb52" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#ff4848" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <Legend />
          <YAxis />
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="votes"
            stroke="#0fbb52"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default VotesChart;
