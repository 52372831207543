import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { socialTypeChart } from "../../services/api";
import { toast } from "react-toastify";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const SocialTypeChart = () => {
  const [record, setRecord] = useState([0, 0, 0, 0]);
  const [label, setLabel] = useState([0, 0, 0]);
  const [latestSpin, setLatestSpin] = useState(false);

  useEffect(() => {
    occupantsDetail();
  }, []);

  const occupantsDetail = async () => {
    setLatestSpin(true);
    let res = await socialTypeChart();
    if (res.code == 200) {
      const labels = res.result.map((item) => item._id);
      const values = res.result.map((item) => item.count);
      setLabel(labels);
      setRecord(values);
      setLatestSpin(false);
    } else {
      // toast.error(res.message);
      // setLatestSpin(false);
    }
  };

  var chartData = {
    labels: label,
    datasets: [
      {
        label: "",
        data: record,
        backgroundColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "#204FA7",
          "#FF4343",
        ],
        borderColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "#204FA7",
          "#FF4343",
        ],
        hoverOffset: 20,
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <h5 className="mb-0 text-start">User Registered</h5>
          <div className="d-flex align-items-center">
            <div
              className="refresh-icon me-2"
              onClick={() => occupantsDetail()}
            >
              <i
                className={
                  "fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")
                }
              ></i>
            </div>
          </div>
        </div>
        <div className="graph occupant-detail-graph">
          <Pie data={chartData} options={options} />
        </div>
      </div>
    </>
  );
};

export default SocialTypeChart;
