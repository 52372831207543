import { Urls } from '../utils/apiUrl';
import { EndPoints } from '../utils/endPoint';
import { Get } from '../helper/localStorage';
import axios from 'axios';



export const login = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.login,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json"
                }
            }
        ).then(async response => {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    });
}

export const getChartData = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + EndPoints.chartData,
            data,
            {
                headers: {
                    "Accept": "Application/type",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getUsers = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.userList,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getPosts = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.postList,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getUnderReviewPosts = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.underReviewPost,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const approvePost = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.approvePost,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const updatePassword = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.changePassword,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export const changeUserStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.updateUserStatus,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const DeletePost = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.deletePost,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getUserDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.userDetails,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const dashboardUserCounts = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.dashboardUserCounts,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const dashboardPostCounts = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.dashboardPostCounts,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const logOut = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.logout,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const Profile = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.myProfile,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const genderGraph = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.genderRatio,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const premiumUserPieChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.getPremiumUserPieChart,{},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const dashboardCount = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.getDashboardCount,{},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const socialTypeChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.socialType, {},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const countryChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.countryChartRoute, {},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export const PostChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.postChart,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const ViewChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.viewChart,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const CommentChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.commentChart,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const VoteChart = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.voteChart,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const postStatistics = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.postStatistics,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const postAverage = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.postAverage,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const postTypeSelectionCheckApi = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.postTypeSelectionCheck,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const updatePostTypeSelectionCheckApi = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.updatePostTypeSelection,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getLabels = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.labels,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const postGroup = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.groups,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getGroups = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.getGroups,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const updateLabel = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.updateLabel,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getLabelsWithGroup = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.getLabelsWithGroup,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const postAdsData = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + EndPoints.postAdsData,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getAdsData = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + EndPoints.getAdsData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${Get('token')}`,
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}