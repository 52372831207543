import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActiveUserChart from "./ActiveUserChart";
import GenderGraph from "./genderGraph";
import SocialTypeChart from "./socialTypeChart";
import CountryChart from "./countryChart";
import PremiumUserPieChart from "./premiumUserPieChart";
import PostChart from "./postsChart";
import {
  dashboardUserCounts,
  dashboardPostCounts,
  dashboardCount,
} from "../../services/api";
import LoadingBar from "react-top-loading-bar";
import ViewsChart from "./viewsChart";
import CommentsChart from "./commentChart";
import VotesChart from "./votesChart";

const Dashboard = () => {
  let navigate = useNavigate();

  const [counts, setCounts] = useState({});
  const [sharedCount, setSharedCounts] = useState(0);
  const [postCount, setPostCount] = useState({});
  const [viewCount, setViewCount] = useState({});
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getCounts();
    getPostCounts();
    viewCommentCount();
  }, []);

  const getCounts = async () => {
    setProgress(20);
    const counts = await dashboardUserCounts();
    if (counts.code == 200) {
      setCounts(counts.result);
    }
    if (counts.code == 401) {
      localStorage.setItem("token", "");
      navigate("/login", { replace: true });
    }
    // if(counts.code == 401){
    //   navigate("/login", { replace: true });
    // }
    setProgress(100);
  };

  const getPostCounts = async () => {
    setProgress(20);
    const counts = await dashboardPostCounts();
    if (counts.code == 200) {
      setPostCount(counts.result);
    }
    if (counts.code == 401) {
      localStorage.setItem("token", "");
      navigate("/login", { replace: true });
    }
    setProgress(100);
  };

  const viewCommentCount = async () => {
    setProgress(20);
    const counts = await dashboardCount();
    setProgress(100);
    if (counts.code == 200) {
      setViewCount(counts.result);
      if (counts.result) {
        if (counts.result.sharedCount.length) {
          setSharedCounts(counts.result.sharedCount[0].totalShares);
        }
      }
    }
    if (counts.code == 401) {
      localStorage.setItem("token", "");
      navigate("/login", { replace: true });
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <div className="d-flex justify-content-between align-items-start mb-4 main-heading p-4">
            <div className="heading mb-5">
              <h1 className="text-white">
                Dashboard
                <span className="ms-2">
                  ( Welcome to the Versus Media Dashboard !!! )
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="row mx-2 mt-n-90 cards">
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Users</p>
                <h5>{counts.totalCount ? counts.totalCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img
                  src="images/total-users.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">New Users Today</p>
                <h5>{counts.newToday ? counts.newToday : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/new-user.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Active Users</p>
                <h5>{counts.activeUsers ? counts.activeUsers : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img
                  src="images/active-user.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Blocked Users</p>
                <h5>{counts.inActiveUsers ? counts.inActiveUsers : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img
                  src="images/inactive-user.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Posts</p>
                <h5>{postCount.totalCount ? postCount.totalCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">New Post Today</p>
                <h5>{postCount.newToday ? postCount.newToday : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Views</p>
                <h5>{viewCount.viewsCount ? viewCount.viewsCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Comments</p>
                <h5>{viewCount.commentsCount ? viewCount.commentsCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Votes</p>
                <h5>{viewCount.voteCounts ? viewCount.voteCounts : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Shares</p>
                <h5>{sharedCount ? sharedCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <div className="card-body d-flex justify-content-between align-items-center inset-outer-shadow bg-white">
              <div className="card-content">
                <p className="mb-1">Total Premium Users</p>
                <h5>{viewCount.premiumUsersCount ? viewCount.premiumUsersCount : 0}</h5>
              </div>
              <div className="card-icon d-flex justify-content-center align-items-center">
                <img src="images/post.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="chart-box card-body p-md-4 p-0">
              <ActiveUserChart />
            </div>
          </div>
          <div className="col-xxl-4 col-xl-5 col-lg-4 mb-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <GenderGraph />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-5 col-lg-4 mb-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <SocialTypeChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-5 col-lg-4 mb-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <PremiumUserPieChart />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 mb-2 mx-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <PostChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mb-2 mx-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <ViewsChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mb-2 mx-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <VotesChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-11 mb-2 mx-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <CommentsChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 mt-5">
            <div className="chart-box">
              <div className="card">
                <div className="card-body">
                  <CountryChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
