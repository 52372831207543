/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import User from "../../images/user.png";
import Hamburger from "../../images/hamburger.svg";

const HeaderTop = (props) => {
  let navigate = useNavigate();

  const toDashboard = () => {
    navigate("/dashboard", { replace: true });
  };
  return (
    <>
      <div
        className="navbar navbar-expand bg-body-tertiary fixed-top"
      >
        <div className="container">
          <a className="navbar-brand">
            <img
              src={Logo}
              alt=""
              className="img-fluid"
              onClick={toDashboard}
            />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item d-flex align-items-center">
                <div className="dropdown me-3">
                  <div
                  // className="btn dropdown-toggle"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                  >
                    <img src={User} alt="" className="img-fluid" />
                  </div>
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Logout
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="toggle-btn d-xl-none d-block">
                  <img src={Hamburger} alt="" className="img-fluid" onClick={props.sidebarOpen} />

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
