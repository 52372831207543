/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Storage } from "../../helper/appResource";
import { toast } from "react-toastify";
import { Profile } from "../../services/api";

const AccountInfo = () => {
  let initialState = {
    userName: "",
    fullName: "",
    email: "",
    organization: "",
  };
  const [state, setState] = useState(initialState);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    Profile().then((profile) => {
      profile.code === 200
        ? setState(profile.result)
        : toast.error(profile.message);
    });
  }, []);

  const onChangeHandle = (event) => {
    setState((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  // const updateAccountInfo = () => {
  //   if (!state.userName || !state.userName.trim())
  //     return toast.error(Storage.USERNAME_REQUIRED);
  //   if (!state.fullName || !state.fullName.trim())
  //     return toast.error(Storage.FULL_NAME_REQUIRED);
  //   setSpinner(true);
  //   editProfile(state).then((data) => {
  //     setSpinner(false);
  //     data.code === 200
  //       ? toast.success(Storage.ACCOUNT_INFO_UPDATED)
  //       : toast.error(data.message);
  //   })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };


  return (
    <div className="mt-4 p-24">
      <form className="d-flex flex-column align-items-center justify-content-center">
        <div className="row justify-content-center gx-3 mb-3 w-100">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Full Name</label>
            <input
              name="fullName"
              className="form-control"
              type="text"
              placeholder="Enter your first name"
              disabled={true}
              value={state.fullName}
            // onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        <div className="row justify-content-center gx-3 mb-3 w-100">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Email address</label>
            <input
              name="email"
              className="form-control"
              type="email"
              placeholder="Enter your email address"
              disabled={true}
              value={state.email}
            />
          </div>
        </div>
        <div className="row justify-content-center gx-3 mb-4 w-100">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Report</label>
            <input
              className="form-control"
              value="Admin"
              type="text"
              placeholder="Enter your organization name"
              disabled={true}
            // onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div>
        {/* <div className="row justify-content-center gx-3 mb-3 w-100">
          <div className="col-md-6 text-left">
            <label className="small mb-1">Organization name (optional)</label>
            <input
              name="organization"
              className="form-control"
              type="text"
              placeholder="Enter your organization name"
              value={state.organization}
              onChange={(event) => onChangeHandle(event)}
            />
          </div>
        </div> */}
        {/* <button
          className="btn btn-primary"
          type="button"
        // onClick={() => updateAccountInfo()}
        >{(spinner ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : "")}
          Update Info
        </button> */}
      </form>
    </div>
  );
};

export default AccountInfo;
