("use Strict");
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUnderReviewPosts,
  DeletePost,
  approvePost,
} from "../../services/api";
import { Storage } from "../../helper/appResource";
import { Set, Get } from "../../helper/localStorage";
import { toast } from "react-toastify";
import Select from "react-select";
import Footer from "../footer/footer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Pagination from "@material-ui/lab/Pagination";
import LoadingBar from "react-top-loading-bar";

const UnderReviewPosts = () => {
  let timerCount = null;
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [List, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    Get("pageNumber") ? Get("pageNumber") : 1
  );
  // const [search, setSearch] = useState(localStorage.getItem("searchKey"));
  const [buttonSpin, setButtonSpin] = useState(false);
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState("");
  const [postId, setPostId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    postListing();
  }, []);

  useEffect(() => {
    postListing();
  }, [pageNumber]);

  const postListing = async () => {
    setProgress(20);
    const listing = await getUnderReviewPosts({
      pageNumber: pageNumber,
    });
    setProgress(100);
    setList(listing.result);
    setCount(Math.ceil(listing.count / 10));
  };

  // const getPostId = (id) => {
  //     Set(Storage.PAGE_NUMBER, pageNumber);
  //     navigate(`/postDetails/:${id}`);
  // };

  const handleChange = (event, value) => {
    setPageNumber(value);
    Set(Storage.PAGE_NUMBER, value);
  };

  const deletePost = async () => {
    setButtonSpin(true);
    const _deletePost = await DeletePost({ postId: postId });
    if (_deletePost.code == 200) {
      postListing();
      setButtonSpin(false);
      handleClose();
    } else {
      toast.error(_deletePost.message);
      setButtonSpin(false);
    }
  };

  const openConfirmDialog = (value, status) => {
    handleShow();
    setPostId(value);
    setDialog(<p>{Storage.DELETE_POST}</p>);
  };

  const approvePostByAdmin = async (id) => {
    const result = await approvePost({ postId: id });
    if (result.code == 200) {
      toast.success(result.message);
      postListing();
    } else {
      toast.error(result.message);
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph">
                <div className="row">
                  <div className="col-md-12">
                    <div className="dashboard-table mb-3 pb-4">
                      <div className="d-flex flex-column flex-lg-row justify-content-between">
                        <h4 className="pl-3 mb-0 mt-3">Under Review Posts</h4>
                        {/* <div className="search-bar">
                                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                                    </div>
                                                </div> */}
                      </div>
                      <hr />
                      <div className="row">
                        {List.map((item, index) => {
                          return (
                            <>
                              <div className="col-lg-4 mb-3" key={index}>
                                <div className={`blog-post ${item.postType}`}>
                                  <div className="card">
                                    <div
                                      className={`images d-flex ${
                                        item.postType === "Horizontal"
                                          ? "flex-column"
                                          : ""
                                      }`}
                                    >
                                      <div className="approve-btn">
                                        {/* <button className="btn btn-success" onClick={() => approvePostByAdmin(item._id)}>Approve</button> */}
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            approvePostByAdmin(item._id)
                                          }
                                        >
                                          <i className="fa fa-check"></i>
                                        </button>
                                      </div>
                                      <div
                                        className="delete-btn"
                                        onClick={() =>
                                          openConfirmDialog(
                                            item._id,
                                            item.active
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                          style={{
                                            color: "#ff4848",
                                            fontSize: 18,
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </div>
                                      {item.postType === "Single" ? (
                                        <>
                                          <div className="first-img">
                                            <img
                                              className="card-img-top"
                                              src={item.firstImage}
                                              alt="Card image cap"
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="first-img">
                                            <img
                                              className="card-img-top"
                                              src={item.firstImage}
                                              alt="Card image cap"
                                            />
                                          </div>
                                          <div className="second-img">
                                            <img
                                              className="card-img-top"
                                              src={item.secondImage}
                                              alt="Card image cap"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="card-body">
                                      <h5 className="card-title">
                                        {item.text}
                                      </h5>
                                      <div className="blog-button d-flex justify-content-between">
                                        <div className="yes-count count">
                                          <i className="fa fa-thumbs-up"></i>{" "}
                                          {item.firstCount}
                                        </div>
                                        <div className="no-count count">
                                          <i className="fa fa-thumbs-down"></i>{" "}
                                          {item.secondCount}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {/* <div className="table-responsive px-2">
                                                <table id="example2" className="table table-hover">
                                                    <tbody>
                                                        {List.map((item, index) => {
                                                            return (
                                                                <tr className="point" key={index.toString()}>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div className="text-center">
                                                                            <h3 className="mb-0">
                                                                                {(pageNumber - 1) * 10 + index + 1}
                                                                            </h3>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div>
                                                                            <h3 className="mb-0">{item.postType}</h3>
                                                                            <h5 className="mb-0">postType</h5>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div>
                                                                            <h3 className="mb-0">
                                                                                {item.text ? item.text : "N/A"}
                                                                            </h3>
                                                                            <h5 className="mb-0">Text</h5>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div>
                                                                            <h3 className="mb-0">
                                                                                {item?.firstCount
                                                                                    ? item.firstCount
                                                                                    : 0}
                                                                            </h3>
                                                                            <h5 className="mb-0">Total Yes</h5>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div>
                                                                            <h3 className="mb-0">
                                                                                {item?.secondCount
                                                                                    ? item.secondCount
                                                                                    : 0}
                                                                            </h3>
                                                                            <h5 className="mb-0">Total No</h5>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <div>
                                                                            <button className="btn btn-success" onClick={() => approvePostByAdmin(item._id)}>Approve</button>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                        onClick={() =>
                                                                            openConfirmDialog(item._id, item.active)
                                                                        }
                                                                    >
                                                                        <div className="text-center">
                                                                            <h3 className="mb-0">
                                                                                <i className="fa fa-trash" aria-hidden="true" style={{ color: "#ff4848", fontSize: 25 }}></i>
                                                                            </h3>
                                                                            <h5 className="mb-0">Delete Post</h5>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        className="border-0"
                                                                    >
                                                                        <i
                                                                            className="fa fa-angle-right"
                                                                            aria-hidden="true"
                                                                        ></i>{" "}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                                
                                                <div>
                                                    {List.length ? (
                                                        <Pagination
                                                            style={{ display: "block", float: "right" }}
                                                            onChange={handleChange}
                                                            page={pageNumber}
                                                            count={count}
                                                        />
                                                    ) : (
                                                        <p
                                                            style={{
                                                                marginBottom: 100,
                                                                marginTop: 25,
                                                                textAlign: "center",
                                                                color: "red",
                                                            }}
                                                        >
                                                            No data found!
                                                        </p>
                                                    )}
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Footer /> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{dialog}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deletePost}>
            {buttonSpin ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnderReviewPosts;
