import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { genderGraph } from "../../services/api";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};


const GenderGraph = () => {
  const [record, setRecord] = useState([0, 0, 0])
  const [latestSpin, setLatestSpin] = useState(false)

  useEffect(() => {
    occupantsDetail();
  }, [])

  const occupantsDetail = async () => {
    setLatestSpin(true)
    let temp = []
    let res = await genderGraph();
    temp.push(res.Men);
    temp.push(res.Women);
    temp.push(res.Binary);
    setRecord(temp)
    setLatestSpin(false)
  }

  var chartData = {
    labels: ["Men", "Women"],
    datasets: [
      {
        label: "",
        data: record,
        backgroundColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        borderColor: [
          "rgba(0, 183, 214, 1)",
          "rgba(232, 206, 70, 1)",
          "rgba(242, 97, 34, 1)",
        ],
        hoverOffset: 20,
        borderWidth: 2,
      },
    ],
  };


  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <h5 className="mb-0 text-start">Genders</h5>
          <div className="d-flex align-items-center">
            <div className="refresh-icon me-2" onClick={() => occupantsDetail()}>
              <i className={"fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")}></i>
            </div>
          </div>
        </div>
        <div className="graph occupant-detail-graph">
          <Pie data={chartData} options={options} />
        </div>
      </div>

    </>
  );
};

export default GenderGraph;
