import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { premiumUserPieChart } from "../../services/api";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  layout: {
    padding: 10,
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",

      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const PremiumUserPieChart = () => {
  const [premium, setPremium] = useState(0);
  const [nonPremium, setNonPremium] = useState(0);
  const [latestSpin, setLatestSpin] = useState(false);

  useEffect(() => {
    occupantsDetail();
  }, []);

  const occupantsDetail = async () => {
    setLatestSpin(true);
    let res = await premiumUserPieChart();
    if (res && res.result && res.result.length)
      res.result.map((item) =>
        item._id == true ? setPremium(item.count) : setNonPremium(item.count)
      );
    setLatestSpin(false);
  };

  var chartData = {
    labels: ["Premium", "Freemium"],
    datasets: [
      {
        label: "",
        data: [premium, nonPremium],
        backgroundColor: ["#204FA7", "#FF4343"],
        borderColor: ["#204FA7", "#FF4343"],
        hoverOffset: 20,
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <div className="card-container">
        <div className="graph-heading d-flex align-items-center justify-content-between">
          <h5 className="mb-0 text-start">Premium Users</h5>
          <div className="d-flex align-items-center">
            <div
              className="refresh-icon me-2"
              onClick={() => occupantsDetail()}
            >
              <i
                className={
                  "fa fa-refresh " + (latestSpin === true ? "fa-spin" : "")
                }
              ></i>
            </div>
          </div>
        </div>
        <div className="graph occupant-detail-graph">
          <Pie data={chartData} options={options} />
        </div>
      </div>
    </>
  );
};

export default PremiumUserPieChart;
