("use Strict");
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import Switch from "react-switch";
import { getAdsData, postAdsData } from "../../services/api";
import { toast } from "react-toastify";

const AdsManagement = () => {
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [googleAds, setGoogleAds] = useState(false);

  useEffect(() => {
    getAds();
  }, []);

  const getAds = async () => {
    setProgress(30);
    const result = await getAdsData();
    setProgress(70);
    if (result.code === 200) {
      setGoogleAds(result.adsData.googleAds);
      setPostCount(result.adsData.postCount);
      setProgress(100);
    } else {
      toast.error(result.message);
      setProgress(100);
    }
  };

  const updateAdsData = async (val) => {
    setProgress(30);
    const result = await postAdsData(val);
    setProgress(70);
    if (result.code === 200) {
      setProgress(100);
      setCount(0);
    } else {
      toast.error(result.message);
      setProgress(100);
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard">
          <section className="content pt-3">
            <div className="container-fluid">
              <div className="new-graph px-2">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="pl-3 mb-4">Ads Management</h4>
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-4">
                    <div className="d-flex justify-content-center align-items-center">
                      <h6 className="me-2">Google Ads (Not Available) : </h6>
                      <Switch
                        onChange={() => {
                          updateAdsData({
                            googleAds: googleAds ? false : true,
                          });
                          setGoogleAds(googleAds ? false : true);
                        }}
                        checked={googleAds}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-center align-items-center">
                      <h6 className="me-2">Post Count : </h6>
                      <input
                        type="text"
                        onChange={(e) => setCount(e.target.value)}
                        className="form-control mb-1 rounded-pill"
                        placeholder={postCount}
                      />
                      {count != 0 && (
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            updateAdsData({ postCount: count });
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AdsManagement;
