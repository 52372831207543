export const Storage = {
    EMAIL_REQUIRED: "Email is required!",
    USER_NAME_REQUIRED: "User Name is required!",
    EMAIL_NOT_VALID: "Please enter a valid email address!",
    PASSWORD_REQUIRED: "Password is required!",
    PASSWORD_VALIDATION: "Password must be 6 to 16 characters",
    YOUTH_GROUP_NAME_REQUIRED: "Youth group name is required!",
    CONTACT_NUMBER_REQUIRED: "Contact number is required",
    BLOCK_REQUIRED: "Block name is required",
    LOCATION_REQUIRED: "Location is required",
    SELECT_COLLECTORS: "Select up to 10 collectors",
    CANT_SELECT_MORE: "You can't select more than 15 collectors!",
    PAGE_NUMBER: "pageNumber",
    SEARCH: "search",
    ACTIVATE_USER: "Are you sure you want to activate this user?",
    DELETE_USER: "Are you sure you want to delete this user?",
    DELETE_POST: "Are you sure you want to delete this Post?",
    DEACTIVATE_USER: "Are you sure you want to block this user?",
    ACTIVATE_COLLECTOR: "Are you sure you want to activate this user?",
    DEACTIVATE_COLLECTOR: "Are you sure you want to deactivate this user?",
    STATUS_SUCCESS: "Status updated successfully!",
    OLD_PASSWORD_REQUIRED: "Enter your old password",
    NEW_PASSWORD_REQUIRED: "Enter new password",
    CONFIRM_PASSWORD_REQUIRED: "Enter confirm password",
    PASSWORD_UPDATED: 'Password updated successfully',
    PASSWORD_NOT_MATCHED: "New password and confirm password didn't match",
    USERNAME_REQUIRED: "Please add username",
    FULL_NAME_REQUIRED: "Please add full name",
    ACCOUNT_INFO_UPDATED: "Account info updated successfully",
    MIN_VOL_REQUIRED: "Please enter minimum volume",
    MAX_VOL_REQUIRED: "Please enter maximum volume",
    PRICE_REQUIRED: "Please enter price",
    LOCATION_TYPE_REQUIRED: "Please enter location type",
    SELECT_COUNTRY: "Please select country"
}