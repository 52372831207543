import React, { PureComponent } from "react";
import { useState, useEffect } from "react";
import { getChartData } from "../../services/api";
import { toast } from "react-toastify";
import Spinner from "react-spinner-material";
import { manageChartData } from "../../services/common";
import LoadingBar from "react-top-loading-bar";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ActiveUserChart = () => {
  const [data, setData] = useState({});
  const [filterType, setFilterType] = useState("DAILY");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  });

  useEffect(() => {
    getChartDetails();
  }, [filterType]);

  const getChartDetails = async () => {
    setProgress(20);
    const result = await getChartData({ filterType: filterType });
    if (result.code === 200) {
      let i = await manageChartData({ type: filterType, list: result.result });
      setData(i);
      setProgress(100);
    } else {
      setProgress(100);
      // toast.error(result.message);
    }
  };

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div>
          <h2 className="mb-3 mb-md-0">Users</h2>
          {/* <p className="mb-4">
            <span className="green-color">(+23%)</span> than last week
          </p> */}
        </div>
        <div className="buttons">
          <button
            onClick={() => setFilterType("DAILY")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filterType == "DAILY" ? " selectedButton" : "")
            }
          >
            Daily
          </button>
          <button
            onClick={() => setFilterType("WEEK")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filterType == "WEEK" ? " selectedButton" : "")
            }
          >
            Week
          </button>
          <button
            onClick={() => setFilterType("MONTH")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filterType == "MONTH" ? " selectedButton" : "")
            }
          >
            Month
          </button>
          <button
            onClick={() => setFilterType("YEAR")}
            className={
              "btn mx-md-2 mb-2 mb-md-0" +
              (filterType == "YEAR" ? " selectedButton" : "")
            }
          >
            Year
          </button>
        </div>
      </div>
      <ResponsiveContainer width={"100%"} aspect={windowWidth <= 576 ? 1 : 2.6}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 30, right: 24, left: -24, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#004AAD" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#D40E0F" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#004AAD" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#D40E0F" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <Legend />
          <YAxis />
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="users"
            stroke="#004AAD"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default ActiveUserChart;
