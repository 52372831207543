/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AccountInfo from "./accountInfo";
import ChangePassword from "./changePassword";
// import Footer from "../footer/footer";
import {
  postTypeSelectionCheckApi,
  updatePostTypeSelectionCheckApi,
} from "../../services/api";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import Switch from "react-switch";

const Settings = () => {
  const [state, setState] = useState(1);
  const [uploadPosts, setUploadPosts] = useState(false);
  const [variationPosts, setVariationPosts] = useState(false);
  const [imageAi, setImageAi] = useState(false);
  const [feeds, setFeeds] = useState(false);
  // const [googleAds, setGoogleAds] = useState(false);
  const [postTypeSelection, setPostTypeSelection] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getCheck();
  }, []);

  const getCheck = async () => {
    setProgress(30);
    const result = await postTypeSelectionCheckApi();
    // console.log(result);
    setProgress(70);
    if (result.code === 200) {
      setFeeds(result.result.feeds);
      // setGoogleAds(result.result.googleAds);
      setImageAi(result.result.imageAi);
      setPostTypeSelection(result.result.postTypeSelection);
      setUploadPosts(result.result.uploadPosts);
      setVariationPosts(result.result.variationPosts);
      setProgress(100);
    } else {
      toast.error(result.message);
      setProgress(100);
    }
  };

  const Navigation = (key) => {
    switch (key) {
      case 1:
        setState(key);
        break;
      case 2:
        setState(key);
        break;
      default:
        break;
    }
  };

  const update = async (val) => {
    // console.log(val);
    setProgress(30);
    const result = await updatePostTypeSelectionCheckApi(val);
    setProgress(70);
    if (result.code === 200) {
      setProgress(100);
    } else {
      toast.error(result.message);
      setProgress(100);
    }
  };
  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <div className="main-wrapper">
        <div className="dashboard content-wrapper">
          <section>
            <h4 className="card-header pt-3 px-3 text-center">
              {state == 1 ? "Acount Info" : "Change Password"}
            </h4>
            <div className="mt-4 px-4 tabs d-flex justify-content-center">
              <div className="nav nav-borders">
                <a
                  className={
                    "nav-link point ms-0 " + (state === 1 ? "active" : "")
                  }
                  onClick={() => Navigation(1)}
                >
                  Account Info
                </a>
                <a
                  className={
                    "nav-link point ms-0 " + (state === 2 ? "active" : "ms-0")
                  }
                  onClick={() => Navigation(2)}
                >
                  Change Password
                </a>
              </div>
            </div>
            {state === 1 ? <AccountInfo /> : <ChangePassword />}
            {/* <Footer/> */}
          </section>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="me-2">Post view selection : </h6>
                <Switch
                  onChange={() => {
                    update({
                      postTypeSelection: postTypeSelection ? false : true,
                    });
                    setPostTypeSelection(postTypeSelection ? false : true);
                  }}
                  checked={postTypeSelection}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="me-2">Upload Posts : </h6>
                <Switch
                  onChange={() => {
                    update({ uploadPosts: uploadPosts ? false : true });
                    setUploadPosts(uploadPosts ? false : true);
                  }}
                  checked={uploadPosts}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="me-2">Variation Posts : </h6>
                <Switch
                  onChange={() => {
                    update({ variationPosts: variationPosts ? false : true });
                    setVariationPosts(variationPosts ? false : true);
                  }}
                  checked={variationPosts}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="me-2">Image Ai (Not Available) : </h6>
                <Switch
                  onChange={() => {
                    update({ imageAi: imageAi ? false : true });
                    setImageAi(imageAi ? false : true);
                  }}
                  checked={imageAi}
                />
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex justify-content-center align-items-center">
                <h6 className="me-2">Google Ads (Not Available) : </h6>
                <Switch
                  onChange={() => {
                    update({ googleAds: googleAds ? false : true });
                    setGoogleAds(googleAds ? false : true);
                  }}
                  checked={googleAds}
                />
              </div>
            </div> */}
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Settings;
