import React from "react";

const date = new Date();
let year = date.getFullYear();
const footer = () => {
  return (
    <>
      <footer className="pt-2 p-24">
        <div className="d-flex justify-content-center align-items-center">
          <div className="copyright">
            <p className="mt-3 mb-0">
              &copy; Versus Media {year}. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
