export const EndPoints = {
    login: "admin/login",
    changePassword: "admin/changePassword",
    userList: "admin/userList",
    postList: "admin/postList",
    underReviewPost: "admin/underReviewPosts",
    updateUserStatus: "admin/changeUserStatus",
    userDetails: "admin/userDetails",
    dashboardUserCounts: "admin/userCounts",
    dashboardPostCounts: "admin/postCounts",
    logout: "admin/logout",
    myProfile: "admin/me",
    genderRatio: "admin/genderGraph",
    getPremiumUserPieChart: "admin/premiumUsersChart",
    getDashboardCount: "admin/dashboardCount",
    socialType: "admin/getSocialTypeChart",
    countryChartRoute: "admin/getCountryChart",
    postChart: "admin/postChart",
    viewChart: "admin/viewChart",
    commentChart: "admin/commentChart",
    voteChart: "admin/voteChart",
    deletedUser: "admin/deletedUsers",
    deletePost: "admin/deletePost",
    chartData: "admin/chartData",
    postStatistics: "admin/postStatistics",
    postAverage: "admin/postAverage",
    approvePost: "admin/approvePost",
    postTypeSelectionCheck: "common/postTypeSelectionCheck",
    updatePostTypeSelection: "admin/updatePostTypeSelection",
    labels: "admin/labels",
    groups: "admin/postGroup",
    getGroups: "admin/groups",
    updateLabel: "admin/updateLabel",
    getLabelsWithGroup: "admin/labelsWithGroup",
    postAdsData: "admin/postAdsData",
    getAdsData: "admin/getAdsData",
}